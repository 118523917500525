import React, { useState } from "react";
import { Button } from "./ui/button";
import { LinkedinOutlined, PaperClipOutlined } from "@ant-design/icons";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { message } from "antd";
import { storage } from "../config/firebaseConfig";
import { ref, listAll, getDownloadURL } from "firebase/storage";

// Helper function to get document URL
export async function getDocumentUrl(uid: string, docType: string): Promise<string> {
  const folderRef = ref(storage, `mobileUsersFiles/${uid}/${docType}/`);
  const files = await listAll(folderRef);

  if (files.items.length === 0) {
    throw new Error("No document found");
  }

  const url = await getDownloadURL(files.items[0]);
  return url;
}

interface DocumentButtonsProps {
  documents: string[];
  candidateUid: string;
}

const DocumentButtons: React.FC<DocumentButtonsProps> = ({
  documents,
  candidateUid,
}) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleDocumentClick = async (doc: string) => {
    if (doc.length > 16) {
      window.open(doc.substring(0, 4) === "http" ? doc : "https://" + doc);
    } else {
      try {
        const url = await getDocumentUrl(
          candidateUid,
          doc === "CV" ? "cv" : "cover_letter"
        );
        setPdfUrl(url);
      } catch (error) {
        console.error("Error fetching document URL:", error);
        message.error("Failed to load the document");
      }
    }
  };

  return (
    <div className="space-y-2 mb-4">
      {documents?.map(
        (doc) =>
          doc !== "" && (
            <Sheet key={doc}>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  className="w-full justify-start"
                  onClick={() => handleDocumentClick(doc)}
                >
                  {doc.length > 16 ? (
                    <>
                      <LinkedinOutlined className="mr-2 h-4 w-4" />
                      {doc.substring(0, 64) + "..."}
                    </>
                  ) : (
                    <>
                      <PaperClipOutlined className="mr-2 h-4 w-4" />
                      {doc}
                    </>
                  )}
                </Button>
              </SheetTrigger>
              <SheetContent
                side="right"
                className="w-[90%] max-w-[90vw] sm:max-w-[880px]"
              >
                <SheetHeader>
                  <SheetTitle>{doc}</SheetTitle>
                </SheetHeader>
                {pdfUrl && (
                  <iframe
                    src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    className="w-full h-[calc(100vh-100px)]"
                    title={doc}
                  />
                )}
              </SheetContent>
            </Sheet>
          )
      )}
    </div>
  );
};

export default DocumentButtons;
