import { Candidate } from "../../types/candidate";
import { Link } from "react-router-dom";
import CandidateListItem from "../CandidateListItem/CandidateListItem";

const NewChatsList = ({
  matches,
  selectedAdId,
}: {
  matches: any[];
  selectedAdId: string;
}) => {
  if (matches.length === 0) {
    return (
      <div className="text-center text-sm text-gray-500">
        Inga chattar hittades
      </div>
    );
  }

  const getPreviewMessage = (match: Candidate) => {
    if (match.latestMessage) {
      return match.latestMessage;
    }

    console.log("No latest message");
    return {
      message: "Ny match! Starta en konversation.",
      timestamp: match.timestamp,
      sender: "system"
    };
  };

  return (
    <div>
      {matches.map((match: Candidate) => (
        <Link
          to={`/chat/${selectedAdId}/${match.uid}`}
          key={match.uid + selectedAdId}
        >
          <CandidateListItem
            key={match.uid}
            candidate={match}
            selectedAdId={selectedAdId}
            previewMessage={getPreviewMessage(match)}
          />
        </Link>
      ))}
    </div>
  );
};

export default NewChatsList;
