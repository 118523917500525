import { message } from "antd";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { useState } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { storage } from "../config/firebaseConfig";
import { AvatarImage, AvatarFallback, Avatar } from "./ui/avatar";
import { Mail, Phone } from "lucide-react";
import {
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
  Card,
} from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import MatchActionsDialog from "./match-actions-dialog";
import DocumentButtons from "./document-buttons";

export const getUnknownFileNames = async (
  personDataUid: string,
  typeOfFile: string
) => {
  try {
    const folderRef = ref(
      storage,
      `mobileUsersFiles/${personDataUid}/${typeOfFile}/`
    );

    listAll(folderRef)
      .then((res) => {
        if (res.items.length === 0) {
          message.error("Användaren har inte laddat upp något CV");
          return;
        }

        res.items.forEach((itemRef) => {
          getDownloadURL(
            // ref(storage, `mobileUsersFiles/${personData?.realFirebaseUserData.uid}/cv/`)
            ref(storage, itemRef.toString())
          )
            .then((url) => {
              // setDownloadedCV(url);
              // setIsLoading(false);
              let a = document.createElement("a");
              a.href = url;
              a.target = "_blank";
              a.download = "*.*";
              a.click();
            })
            .catch((e) => {
              console.log(e);
              alert("An error occured while fetching user data");
            });

          return itemRef;
        });
      })
      .catch((error) => {
        console.log(error);
        alert("An error occured while fetching user data");
      });
  } catch (error) {
    console.log(error);
    alert("An error occured while fetching user data");
  }
};

export default function MatchCard({
  candidate,
  selectedAdId,
  setSelectedMatch,
  refreshUsers,
}: {
  candidate: any;
  selectedAdId: string;
  setSelectedMatch: (match: any) => void;
  refreshUsers: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [value /* loading, error */] = useDownloadURL(
    ref(storage, `mobileUsersFiles/${candidate?.uid}/profile_image`)
  );
  const [showMoreTags, setShowMoreTags] = useState(false);
  /*   const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleDocumentClick = async (doc: string) => {
    if (doc.length > 16) {
      window.open(doc.substring(0, 4) === "http" ? doc : "https://" + doc);
    } else {
      try {
        const url = await getDocumentUrl(
          candidate?.realFirebaseUserData.uid,
          doc === "CV" ? "cv" : "cover_letter"
        );
        setPdfUrl(url);
      } catch (error) {
        console.error("Error fetching document URL:", error);
        message.error("Failed to load the document");
      }
    }
  }; */

  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>Läs mer om kandidaten</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex items-center space-x-4 mb-4">
          <Avatar className="w-16 h-16">
            {value ? (
              <AvatarImage
                className="object-cover"
                src={value}
                alt={candidate.personName}
              />
            ) : (
              <AvatarFallback>
                {candidate?.personName.substring(0, 1)}
              </AvatarFallback>
            )}
          </Avatar>
          <div>
            <h2 className="text-xl font-semibold mb-0">
              {`${candidate.personName}, ${candidate.age} år`}
            </h2>
            <p className="text-sm text-muted-foreground mb-0">
              {`${candidate.currentJobTitle}, ${candidate.currentCompany}`}
            </p>
          </div>
        </div>
        <p className="text-sm mb-4">{candidate.contentText}</p>
        <div className="flex flex-wrap gap-2 mb-4">
          {candidate.tags && candidate.tags.length > 0 && (
            <>
              <div className="flex flex-wrap gap-2">
                {(showMoreTags
                  ? candidate.tags
                  : candidate.tags.slice(0, 10)
                ).map((tag: string, index: number) => (
                  <Badge key={tag + index} className="h-6 flex items-center">
                    {tag}
                  </Badge>
                ))}
              </div>
              {candidate.tags.length > 10 && (
                <Button
                  variant="outline"
                  size="sm"
                  className="h-6 flex items-center"
                  onClick={() => setShowMoreTags(!showMoreTags)}
                >
                  {showMoreTags
                    ? "Visa färre"
                    : `+${candidate.tags.length - 10} fler`}
                </Button>
              )}
            </>
          )}
        </div>
        <div className="space-y-2 mb-4">
          <DocumentButtons
            documents={candidate.personalDocuments}
            candidateUid={candidate.uid}
          />
          {/* {candidate.personalDocuments?.map(
            (doc: any) =>
              doc !== "" && (
                <Sheet key={doc}>
                  <SheetTrigger asChild>
                    <Button
                      variant="outline"
                      className="w-full justify-start"
                      onClick={() => handleDocumentClick(doc)}
                    >
                      {doc.length > 16 ? (
                        <>
                          <LinkedinOutlined className="mr-2 h-4 w-4" />
                          {doc.substring(0, 64) + "..."}
                        </>
                      ) : (
                        <>
                          <PaperClipOutlined className="mr-2 h-4 w-4" />
                          {doc}
                        </>
                      )}
                    </Button>
                  </SheetTrigger>
                  <SheetContent
                    side="right"
                    className="w-[90%] max-w-[90vw] sm:max-w-[880px]"
                  >
                    <SheetHeader>
                      <SheetTitle>{doc}</SheetTitle>
                    </SheetHeader>
                    {pdfUrl && (
                      <iframe
                        src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                        className="w-full h-[calc(100vh-100px)]"
                        title={doc}
                      />
                    )}
                  </SheetContent>
                </Sheet>
              )
          )} */}
        </div>
        <div className="space-y-2">
          <h3 className="text-sm font-semibold">Kontaktinformation</h3>
          <div className="flex items-center space-x-2 text-sm">
            <Mail className="h-4 w-4 text-muted-foreground" />
            <span>{candidate?.realFirebaseUserData.email}</span>
          </div>
          <div className="flex items-center space-x-2 text-sm">
            <Phone className="h-4 w-4 text-muted-foreground" />
            <span>
              {candidate?.realFirebaseUserData.phoneNumber ||
                "(OBS! Användaren har inte lagt in något telefonnummer)"}
            </span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        {/*  <Button asChild>
          <Link to={`/chat/${"selectedAd.id"}/${"selectedMatch.uid"}`}>
            <MessageCircle className="mr-2 h-4 w-4" />
            Chatta med kandidaten
          </Link>
        </Button> */}
        <MatchActionsDialog
          candidate={candidate}
          selectedAdId={selectedAdId}
          setSelectedMatch={setSelectedMatch}
          refreshUsers={refreshUsers}
        />
      </CardFooter>
    </Card>
  );
}
