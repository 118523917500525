import { Separator } from "@radix-ui/react-separator";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  /*   BreadcrumbLink,
  BreadcrumbSeparator, */
  BreadcrumbPage,
} from "./ui/breadcrumb";
import { SidebarTrigger } from "./ui/sidebar";
import { useProfile } from "@/context/ProfileContext";
import { getPathTitle } from "@/lib/nav";
import { ModeToggle } from "./mode-toggle";
import { Button } from "./ui/button";

export default function AppHeader() {
  const location = useLocation();
  const {
    businessProfile,
    isSignedInAsBusinessMember,
    signOutAsBusinessMember,
  } = useProfile();
  const path = location.pathname.split("/")[1];
  const pathTitle = path === "profile" ? "Profil" : getPathTitle("/" + path);

  return (
    <header className="sticky top-0 gap-2 border-b z-50">
      {isSignedInAsBusinessMember && (
        <div className="flex bg-graftus-light-blue p-4 justify-between">
          <div className="flex items-center gap-2">
            Du är just nu inloggad som företaget{" "}
            <Link to="/settings" className="text-primary">
              {businessProfile?.business}
            </Link>
          </div>
          <Button onClick={signOutAsBusinessMember}>
            Logga ut som företaget
          </Button>
        </div>
      )}
      <div className="flex shrink-0 items-center p-4 justify-between bg-background">
        <div className="flex items-center gap-2">
          <div className="flex lg:hidden">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
          </div>

          <Breadcrumb>
            <BreadcrumbList className="mb-0">
              {/* <BreadcrumbItem className="hidden md:block">
              <BreadcrumbLink href="#">All</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" /> */}
              <BreadcrumbItem>
                <BreadcrumbPage>{pathTitle}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <Separator orientation="vertical" className="mr-2 h-4" />
          {/*   <JobAdsList
          selectedAd={selectedAd}
          jobAdsSnapshot={[]!}
          onAdClick={() => {}}
        /> */}
        </div>
        <div className="flex items-center gap-2">
          <ModeToggle />
          {/*  <Novu /> */}
          <span className="text-sm">
            Välkommen,{" "}
            <Link to="/profile" className="text-primary">
              {businessProfile?.name}
            </Link>
          </span>
        </div>
      </div>
    </header>
  );
}
