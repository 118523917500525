import { cn } from "@/lib/utils";
import { Timestamp } from "firebase/firestore";

const MessageTimestamp = ({ timestamp, className }: { timestamp: Timestamp, className?: string }) => {
  console.log(timestamp);
  const messageDate =
    timestamp.seconds === undefined
      ? new Date(timestamp as unknown as number)
      : new Date(timestamp.seconds * 1000);
  const currentDate = new Date();

  // Compare only the year, month, and day parts of the dates
  const isOlderMessage =
    messageDate.getFullYear() < currentDate.getFullYear() ||
    messageDate.getMonth() < currentDate.getMonth() ||
    (messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getDate() < currentDate.getDate());

  // Locale for Sweden
  const locale = "sv-SE";

  // Date and Time options for formatting
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  // Format the timestamp based on the message date
  const formattedTimestamp = isOlderMessage
    ? `${messageDate.toLocaleDateString(
        locale,
        dateOptions
      )} ${messageDate.toLocaleTimeString(locale, timeOptions)}`
    : messageDate.toLocaleTimeString(locale, timeOptions);

  return <div className={cn("text-[10px] w-full", className)}>{formattedTimestamp}</div>;
};

export default MessageTimestamp;
