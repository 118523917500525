import { FunctionComponent, useEffect, useState } from "react";
import Chat from "../components/Chat/Chat";
import { useAdRedirect } from "../lib/useAds";
import { Link, useParams } from "react-router-dom";
import { Candidate } from "@/types/candidate";
import MatchCard from "@/components/match-card";
import MatchNotes from "@/components/MatchNotes/MatchNotes";
import { useUsers } from "@/context/UsersContext";
import { getReciprocalMatchCandidateIds } from "@/lib/helpers";
import ChatMobileList from "@/components/chat-mobile-list";
import { useProfile } from "@/context/ProfileContext";
import TeamtailorCandidateButton from "@/components/teamtailor-candidate-button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface ChatViewProps {}

const ChatView: FunctionComponent<ChatViewProps> = () => {
  const { id: id, userId: selectedCandidate } = useParams<{
    id?: string;
    userId?: string;
  }>();
  const { selectedAd, adsSnapshot, adsLoading, navigateToAd } =
    useAdRedirect("/chat");

  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<Candidate | null>(null);
  const [fetchingUsers, setFetchingUsers] = useState<boolean>(true);
  const [refreshUsers, setRefreshUsers] = useState<number>(0);
  const { users /* loading */ } = useUsers();
  const { businessProfile } = useProfile();

  console.log(fetchingUsers);

  useEffect(() => {
    if (
      (adsSnapshot?.docs.length === 0 && !adsLoading) ||
      (!adsSnapshot && !adsLoading)
    ) {
      console.log("No ads found");
      setFetchingUsers(false);
      return;
    }
    const fillArrayWithData = async () => {
      const data = await getUsers();
      setFetchingUsers(false);

      setFilteredUsers(data as any[]);
    };

    if (selectedAd) {
      fillArrayWithData();
    }

    const unreadMessages = localStorage.getItem("unreadMessages");
    if (unreadMessages) {
      localStorage.removeItem("unreadMessages");
    }
  }, [selectedAd, refreshUsers]);

  const getUsers = async () => {
    setFetchingUsers(true);
    if (selectedAd?.id === undefined) return;
    try {
      const reciprocalMatches = await getReciprocalMatchCandidateIds(
        selectedAd?.id
      );

      const finalArray: any[] | PromiseLike<any[]> = [];

      for (const user of users as any[]) {
        if (user.uid) {
          const match = reciprocalMatches?.find(
            (match) => match.candidateId === user.uid
          );

          if (match) {
            let name = user.givenName;
            let firstName = name.split(" ")[0];
            let currentYear = new Date().getFullYear();

            finalArray.push({
              uid: user?.uid,
              personName: firstName + " " + user?.surname,
              age: currentYear - parseInt(user?.birthday.substring(0, 4)), //user?.birthday,
              currentJobTitle: user?.latestOccupation,
              currentCompany: user?.latestEmployer,
              personalDocuments: [
                user?.hasCv ? "CV" : "",
                user?.hasCoverLetter ? "Personligt brev" : "",
                user?.linkedinProfileLink ? user?.linkedinProfileLink : "",
              ],
              contentText: user?.aboutMeText,
              // candidateAlreadyMatchedWithYou: userMatchedWithAdTemp, // needs to check jobAds
              youMatchedWithCandidate: false, // needs to check jobAds
              bothPartiesMatched: false, // needs to check jobAds
              removedCandidate: false, // needs to check jobAds
              realFirebaseUserData: user,
              tags: user?.tags,
              userQuit: false,
              userHired: false,
              matchId: match.matchId, // adding matchId to the new user object
            });
          }
        }
      }

      return finalArray.filter((obj: any) => obj !== undefined);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedCandidate && filteredUsers) {
      console.log("In here", selectedCandidate);
      const user = filteredUsers?.find(
        (user) => user.uid === selectedCandidate
      );
      console.log("Setting user to", user);
      setSelectedMatch(user);
    }

    if (!selectedCandidate) {
      setSelectedMatch(null);
    }
  }, [selectedCandidate, filteredUsers, navigateToAd]);

  console.log(id);

  useEffect(() => {
    const unreadMessages = localStorage.getItem("unreadMessage");
    if (unreadMessages) {
      localStorage.removeItem("unreadMessage");
    }
  }, []);

  if (!selectedCandidate) {
    return <ChatMobileList />;
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col w-full lg:w-[550px] 2xl:w-[750px] gap-2">
          {selectedAd && selectedCandidate && <Chat />}
        </div>

        <div className="flex flex-col flex-1 w-full lg:max-w-[550px]">
          {selectedAd && selectedMatch ? (
            <>
              <MatchCard
                candidate={selectedMatch}
                selectedAdId={selectedAd.id}
                setSelectedMatch={setSelectedMatch}
                refreshUsers={setRefreshUsers}
              />

              <MatchNotes
                jobAdId={selectedAd.id}
                candidateId={selectedMatch.uid}
              />
              <Card className="mt-4">
                <CardHeader>
                  <CardTitle>Integrationer</CardTitle>
                </CardHeader>
                <CardContent>
                  {businessProfile?.integrations?.teamtailor?.apiKey ? (
                    <TeamtailorCandidateButton candidate={selectedMatch} />
                  ) : (
                    <div className="text-sm">
                      Ingen integrationer konfigurerade. Vänligen lägg till en i
                      Integrationer-inställningar genom att klicka{" "}
                      <Link
                        to="/integrations"
                        className="underline text-graftus-dark-blue"
                      >
                        här
                      </Link>
                      .
                    </div>
                  )}
                </CardContent>
              </Card>
            </>
          ) : !selectedCandidate && !selectedAd ? (
            <span>Välj en chatt</span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChatView;
