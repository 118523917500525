import {
  Timestamp,
  addDoc,
  limit,
  orderBy,
  query,
  where,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import {
  FormEvent,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from "react";
import { /* Link */ Link, useParams } from "react-router-dom";
import { db } from "../../config/firebaseConfig";
import { collection } from "firebase/firestore";
import MessageTimestamp from "./MessageTimestamp";
import { useProfile } from "../../context/ProfileContext";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { message, Spin } from "antd";
import { Input } from "../ui/input";
import { Loader2, SendIcon } from "lucide-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "../ui/card";
import { Button } from "../ui/button";
import CandidateListItemWithoutChatPreview from "../CandidateListItem/CandidateListItem-WithoutChatPreview";
import { useAdRedirect } from "@/lib/useAds";
import FavoritePopup from "./FavoritePopup";
import { format } from "date-fns";
import { sv } from "date-fns/locale";
import { isSameDay } from "date-fns";

const statusReasonMap = {
  "user-quit-process": "kandidaten avbröt processen",
  "business-quit-process": "företaget avbröt processen",
  "business-hired-candidate": "kandidaten blev anställd",
  "business-hired-other": "företaget anställde någon annan",
  // Add more translations as needed
};

const Chat: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id: adId, userId: selectedCandidate } = useParams<{
    id?: string;
    userId?: string;
  }>();
  const { businessProfile } = useProfile();
  const { selectedAd } = useAdRedirect("/chat");

  const matchQuery = query(
    collection(db, `TopLevelReciprocalMatches`),
    where("jobAdId", "==", adId),
    where("candidateId", "==", selectedCandidate),
    limit(1)
  );
  const [matchData] = useCollectionData(matchQuery);
  const q = query(
    collection(db, `Chats/${adId}/Messages`),
    where("sellerUid", "==", selectedCandidate),
    orderBy("timestamp", "desc")
  );
  const [messageData] = useCollectionData(q);
  const favoritesQuery = query(
    collection(db, `JobAds/${adId}/Favorites`),
    where("userId", "==", selectedCandidate)
  );
  const [isFavorite] = useCollectionData(favoritesQuery);
  console.log("isFavorite", isFavorite);

  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const markLatestMessageAsRead = async () => {
      if (messageData && messageData.length > 0) {
        const latestMessage = messageData[0];
        if (
          latestMessage.sender === "seller" &&
          !latestMessage.readByBusiness
        ) {
          const messagesRef = collection(db, `Chats/${adId}/Messages`);
          const latestMessageQuery = query(
            messagesRef,
            where("sellerUid", "==", selectedCandidate),
            where("sender", "==", "seller"),
            orderBy("timestamp", "desc"),
            limit(1)
          );
          const querySnapshot = await getDocs(latestMessageQuery);
          if (!querySnapshot.empty) {
            const latestMessageDoc = querySnapshot.docs[0];
            await updateDoc(latestMessageDoc.ref, { readByBusiness: true });
          }
        }
      }
    };

    markLatestMessageAsRead();
  }, [messageData, adId, selectedCandidate]);

  const sendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newMessage.trim() === "") {
      return;
    }
    console.log("Sending message:", newMessage);
    setLoading(true);
    try {
      await addDoc(collection(db, `Chats/${adId}/Messages`), {
        message: newMessage,
        timestamp: Timestamp.now(),
        sender: "business",
        name: businessProfile.business,
        sellerUid: selectedCandidate,
        readBySeller: false,
      });
      setNewMessage("");
    } catch (e) {
      message.error("Något gick fel när meddelandet skulle skickas");
    } finally {
      setLoading(false);
    }
  };

  const messageListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messageData]);

  const shouldShowDateSeparator = (
    currentMessage: any,
    previousMessage: any
  ) => {
    if (!previousMessage) return true;

    const currentDate = currentMessage.timestamp.toDate();
    const previousDate = previousMessage.timestamp.toDate();

    return !isSameDay(currentDate, previousDate);
  };

  if (!selectedCandidate) {
    return <Spin />;
  }

  return (
    <Card className="h-[calc(100vh-120px)] flex flex-col sticky top-[84px]">
      <CardHeader className="flex-shrink-0 border-b py-2 shadow-sm">
        <CardTitle className="flex gap-2 justify-between m-0">
          <CandidateListItemWithoutChatPreview
            candidateId={selectedCandidate!}
          />
          <div className="lg:hidden">
            <Link to="/chat">
              <Button variant="outline">Tillbaka</Button>
            </Link>
          </div>
          {/*  <CvButton candidateId={selectedCandidate!} /> */}
        </CardTitle>
      </CardHeader>
      {matchData &&
        (matchData[0].status === "archived" ||
          matchData[0].status === "hired" ||
          matchData[0].status === "businessDeclined" ||
          matchData[0].status === "candidateDeclined") && (
          <div className="pr-0 bg-graftus-light-blue">
            <div className="text-center text-sm">
              Annonsen är arkiverad{" "}
              {matchData[0].statusReason &&
                `eftersom att ${
                  statusReasonMap[
                    matchData[0].statusReason as keyof typeof statusReasonMap
                  ] || matchData[0].statusReason
                }`}
            </div>
          </div>
        )}
      <CardContent className="flex-grow overflow-hidden pr-0">
        <div
          ref={messageListRef}
          className="flex flex-col-reverse overflow-y-auto overflow-x-hidden h-full scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-black scrollbar-track-transparent scroll-smooth"
        >
          {(isFavorite &&
            isFavorite.length === 0 &&
            selectedAd?.adType === "automated") ||
            (isFavorite &&
              isFavorite.length === 0 &&
              selectedAd?.adType === "automated-paused" && (
                <FavoritePopup
                  selectedAd={selectedAd}
                  selectedCandidate={selectedCandidate}
                  messageLength={messageData?.length!}
                />
              ))}
          {messageData?.map((message, index) => {
            const previousMessage = messageData[index + 1];
            const showDateSeparator = shouldShowDateSeparator(
              message,
              previousMessage
            );

            return (
              <>
                <div
                  className={`w-fit mb-2.5 max-w-[80%] ${
                    message.sender === "business" ||
                    message.sender === "business-automated" ||
                    message.sender === "business-ai"
                      ? "self-end"
                      : ""
                  }`}
                  key={message.id}
                >
                  {(message.sender === "business-automated" ||
                    message.sender === "business-ai") && (
                    <span className="text-xs text-gray-500">
                      Via Julia (AI)
                    </span>
                  )}
                  <div
                    className={`p-2.5 rounded-md text-xs w-fit list-none ${
                      message.sender === "business" ||
                      message.sender === "business-automated" ||
                      message.sender === "business-ai"
                        ? "bg-[#0036ff38] dark:bg-[#0036ff38] ml-auto mr-[18px]"
                        : "bg-[#f5f5f5] dark:bg-[#222222]"
                    }`}
                  >
                    {message.message}
                  </div>
                  <MessageTimestamp
                    timestamp={message.timestamp}
                    className={
                      message.sender === "system" ||
                      message.sender === "business" ||
                      message.sender === "business-automated"
                        ? "text-right ml-auto pr-[18px]"
                        : ""
                    }
                  />
                </div>
                {showDateSeparator &&
                  !isSameDay(
                    message.timestamp.toDate(),
                    matchData?.[0]?.timestamp.toDate()
                  ) && (
                    <div className="flex justify-center my-4">
                      <div className="bg-muted/50 rounded-full px-4 py-1">
                        <span className="text-xs text-muted-foreground">
                          {format(message.timestamp.toDate(), "d MMMM yyyy", {
                            locale: sv,
                          })}
                        </span>
                      </div>
                    </div>
                  )}
              </>
            );
          })}
          {matchData && matchData[0]?.timestamp && (
            <div className="flex flex-col items-center mb-4 mt-2 mr-[18px] pb-2">
              <div className="flex justify-center my-4">
                <div className="bg-muted/50 rounded-full px-4 py-1">
                  <span className="text-xs text-muted-foreground">
                    {format(matchData[0].timestamp.toDate(), "d MMMM yyyy", {
                      locale: sv,
                    })}
                  </span>
                </div>
              </div>
              <div className="bg-muted/50 rounded-md px-4 py-2 flex items-center gap-2 w-full">
                <svg
                  className="w-4 h-4 text-graftus-blue"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 6L9 17L4 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="text-sm text-muted-foreground">
                  <div>Matchning</div>
                  <div className="text-xs">
                    Kandidaten matchades med din jobbannons.
                  </div>
                </div>
                <div className="ml-auto text-muted-foreground">
                  <span className="text-xs">
                    {format(matchData[0].timestamp.toDate(), "HH:mm", {
                      locale: sv,
                    })}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex-shrink-0">
        <form onSubmit={sendMessage} className="flex w-full gap-2">
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder={
              matchData &&
              (matchData[0].status === "archived" ||
                matchData[0].status === "hired" ||
                matchData[0].status === "businessDeclined" ||
                matchData[0].status === "candidateDeclined")
                ? "Annonsen är arkiverad"
                : "Skriv ett meddelande..."
            }
            disabled={
              matchData &&
              (matchData[0].status === "archived" ||
                matchData[0].status === "hired" ||
                matchData[0].status === "businessDeclined" ||
                matchData[0].status === "candidateDeclined")
            }
            className="flex-1"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !e.altKey) {
                e.preventDefault();
                sendMessage(e as unknown as FormEvent<HTMLFormElement>);
              }
            }}
          />
          <Button
            type="submit"
            disabled={
              loading ||
              (matchData &&
                (matchData[0].status === "archived" ||
                  matchData[0].status === "hired" ||
                  matchData[0].status === "businessDeclined" ||
                  matchData[0].status === "candidateDeclined"))
            }
          >
            {loading ? (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <SendIcon className="mr-2 h-4 w-4" />
            )}
            Skicka
          </Button>
        </form>
      </CardFooter>
    </Card>
  );
};

export default Chat;
