import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { onAuthStateChanged, getAuth, User } from "firebase/auth";
import {
  doc,
  getDoc,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { app } from "../config/firebaseConfig";
import OneSignal from "react-onesignal";
import * as Sentry from "@sentry/browser";

interface ProfileContextProps {
  businessProfile: any | null;
  memberProfile: any | null;
  loading: boolean;
  inactiveUser: boolean;
  setProfileCreated?: (created: boolean) => void;
  unreadMessages: number;
  setUnreadMessages: (unreadMessages: number) => void;
  signInAsBusinessMember: (businessUid: string) => Promise<void>;
  signOutAsBusinessMember: () => Promise<void>;
  isSignedInAsBusinessMember: boolean;
}

const ProfileContext = createContext<ProfileContextProps | undefined>(
  undefined
);

interface ProfileProviderProps {
  children: ReactNode;
}

export const ProfileProvider: React.FC<ProfileProviderProps> = ({
  children,
}) => {
  const [businessProfile, setBusinessProfile] = useState<any | null>(null);
  const [memberProfile, setMemberProfile] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [inactiveUser, setInactiveUser] = useState<boolean>(false);
  const [profileCreated, setProfileCreated] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [isSignedInAsBusinessMember, setIsSignedInAsBusinessMember] =
    useState<boolean>(false);

  const auth = getAuth(app);
  const db = getFirestore(app);

  // Function to sign in as a business member
  const signInAsBusinessMember = async (businessUid: string) => {
    if (!businessProfile.isAdmin) {
      return;
    }
    try {
      // Fetch Business document using the provided business UID
      const businessRef = doc(db, "Businesses", businessUid);
      const businessSnap = await getDoc(businessRef);

      if (businessSnap.exists()) {
        const businessData = businessSnap.data();
        setBusinessProfile(businessData);
        setIsSignedInAsBusinessMember(true);
        console.log(`Admin signed in as business: ${businessUid}`);
      } else {
        console.log("No matching business document found");
      }
    } catch (error) {
      console.error("Error signing in as business member: ", error);
    }
  };

  const signOutAsBusinessMember = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("Cant sign out as business member, no user found");
      return;
    }
    fetchBusinessMemberAndProfile(user);
    setIsSignedInAsBusinessMember(false);
  };

  const fetchBusinessMemberAndProfile = async (user: User) => {
    try {
      // Fetch BusinessMember document
      const memberQuery = query(
        collection(db, "BusinessMember"),
        where("uid", "==", user.uid)
      );
      const memberSnapshot = await getDocs(memberQuery);

      if (!memberSnapshot.empty) {
        const memberDoc = memberSnapshot.docs[0];
        const memberData = memberDoc.data();
        setMemberProfile(memberData);
        OneSignal.login(memberData.uid);
        OneSignal.User.addTags({
          businessId: memberData.business,
        });
        OneSignal.User.addEmail(memberData.email);
        Sentry.setUser({
          id: memberData.uid,
          email: memberData.email,
        });

        // Fetch Business document using the business ID from BusinessMember
        const businessRef = doc(db, "Businesses", memberData.business);
        const businessSnap = await getDoc(businessRef);

        if (businessSnap.exists()) {
          const businessData = businessSnap.data();
          setBusinessProfile(businessData);
        } else {
          console.log("No matching business document found");
        }
      } else {
        console.log(
          "No matching BusinessMember document found, creating one..."
        );

        // Create a new BusinessMember document for the user
        const newMemberData = {
          uid: user.uid,
          business: user.uid, // Replace with actual default business ID or logic to determine it
          email: user.email,
          // Add other necessary fields here
        };
        const newMemberRef = doc(db, "BusinessMember", user.uid);
        await setDoc(newMemberRef, newMemberData);
        setMemberProfile(newMemberData);

        // Fetch Business document using the business ID from the new BusinessMember
        const businessRef = doc(db, "Businesses", newMemberData.business);
        const businessSnap = await getDoc(businessRef);

        if (businessSnap.exists()) {
          const businessData = businessSnap.data();
          await updateDoc(newMemberRef, { name: businessData.name });
          setBusinessProfile(businessData);
        } else {
          console.log("No matching business document found");
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchBusinessMemberAndProfile(user);
      } else {
        setLoading(false);
        setMemberProfile(null);
        setBusinessProfile(null);
        Sentry.setUser(null);
        OneSignal.logout();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db, profileCreated]);

  useEffect(() => {
    if (!businessProfile) return;
    if (
      !businessProfile.trial &&
      businessProfile.sub_still_active !== "active"
    ) {
      setInactiveUser(true);
      console.log("User is inactive");
    }
  }, [businessProfile]);

  return (
    <ProfileContext.Provider
      value={{
        businessProfile,
        memberProfile,
        loading,
        inactiveUser,
        setProfileCreated,
        unreadMessages,
        setUnreadMessages,
        signInAsBusinessMember,
        signOutAsBusinessMember,
        isSignedInAsBusinessMember,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error("useUsers must be used within a UsersProvider");
  }
  return context;
};
