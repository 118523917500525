import { Navigate, Outlet } from "react-router-dom";
// import { auth } from "../config/firebaseConfig";
// import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { Layout, Spin } from "antd";
import "./App.css";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  getTokenLocal,
  onMessageListener,
} from "../config/firebaseConfig";
import styled from "styled-components";
import Hotjar from "@hotjar/browser";
import { useEffect, useState } from "react";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import AppSidebar from "@/components/app-sidebar";
import AppHeader from "@/components/app-header";
import { Toaster } from "@/components/ui/toaster";
const siteId = 3676943;
const hotjarVersion = 6;

const { Content } = Layout;

const AppLayout = () => {
  const [user, loading] = useAuthState(auth);
  const [isTokenFound, setTokenFound] = useState(false);
  //getToken(setTokenFound);

  /*   const {
    token: { colorBgContainer },
  } = theme.useToken(); */

  Hotjar.init(siteId, hotjarVersion);

  useEffect(() => {
    const init = async () => {
      console.log("🏁 AppLayout init starting...");
      console.log("👤 Current user:", user ? "Logged in" : "Not logged in");

      if (user) {
        console.log("🔄 Getting FCM token...");
        await getTokenLocal((success) => {
          console.log(
            "📱 Token setup complete:",
            success ? "Success" : "Failed"
          );
          setTokenFound(success);
          console.log("Token found:", isTokenFound);
        });

        onMessageListener()
          .then((payload: any) => {
            console.log("📬 Foreground message received:", payload);
          })
          .catch((err) =>
            console.error("Error receiving foreground message:", err)
          );

        console.log("✅ Token setup process finished");
      }
    };

    init();
  }, [user]);

  useEffect(() => {
    if (user && !loading) {
      // Create and append HubSpot script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "hs-script-loader";
      script.async = true;
      script.defer = true;
      script.src = "//js-eu1.hs-scripts.com/25702663.js";
      document.body.appendChild(script);

      // Cleanup function to remove script when component unmounts
      return () => {
        const existingScript = document.getElementById("hs-script-loader");
        if (existingScript) {
          existingScript.remove();
        }
      };
    }
  }, [user, loading]);

  if (!user && !loading) {
    return <Navigate to="/sign-in" />;
  }

  if (loading) {
    return (
      <Layout hasSider className="app-container">
        <Layout style={{ minHeight: "100vh" }}>
          <Content style={{ margin: "24px 16px 0" }}>
            <LoadingContent
              style={{ display: "flex", flexDirection: "column" }}
            >
              <img src="/graftus_logo.jpg" style={{ height: 42 }} alt="logo" />
              <Spin />
            </LoadingContent>
          </Content>
        </Layout>
      </Layout>
    );
  }

  return (
    <SidebarProvider
      defaultOpen={false}
      style={
        {
          "--sidebar-width": "350px",
        } as React.CSSProperties
      }
    >
      <AppSidebar />
      <SidebarInset>
        <AppHeader />
        <div className="flex flex-1 flex-col gap-4 p-4 bg-[#fafafa] dark:bg-[#1a1a1a]">
          <Outlet />
          <Toaster />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default AppLayout;

const LoadingContent = styled(Content)`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    margin-bottom: 25px;
  }

  .ant-spin {
    /*  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }
`;
