import * as React from "react";
import {
  BriefcaseBusiness,
  ChevronsUpDown,
  HomeIcon,
  LogOut,
  MessageCircle,
  Settings,
  User,
  UserSearch,
  Workflow,
} from "lucide-react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  /* SidebarMenuBadge, */
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

import ChatSidebar from "./chat-sidebar";
import { useProfile } from "@/context/ProfileContext";
import { auth } from "@/config/firebaseConfig";
import { useSignOut } from "react-firebase-hooks/auth";

// This is sample data
export const navMain = [
  {
    title: "Dashboard",
    url: "/",
    icon: HomeIcon,
    isActive: true,
  },
  {
    title: "Utforska",
    url: "/explore",
    icon: UserSearch,
    isActive: false,
  },
  {
    title: "Matchningar",
    url: "/chat",
    icon: MessageCircle,
    isActive: false,
  },
  {
    title: "Annonser",
    url: "/ads",
    icon: BriefcaseBusiness,
    isActive: false,
  },
  {
    title: "Inställningar",
    url: "/business",
    icon: Settings,
    isActive: false,
  },
  {
    title: "Integrationer",
    url: "/integrations",
    icon: Workflow,
    isActive: false,
  },
];

const adminNav = [
  {
    title: "Annonser",
    url: "/admin/ads",
    icon: BriefcaseBusiness,
  },
  {
    title: "Företag",
    url: "/admin/businesses",
    icon: BriefcaseBusiness,
  },
];

export default function AppSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar>) {
  const location = useLocation();
  const { memberProfile, businessProfile } = useProfile();
  const { setOpen } = useSidebar();
  /*   const [unreadMessages, setUnreadMessages] = useState<number>(0); */

  useEffect(() => {
    if (location.pathname.includes("chat")) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    /*    const unreadMessages = localStorage.getItem("unreadMessages");
    if (unreadMessages) {
      console.log("unreadMessages", unreadMessages);
      setUnreadMessages(parseInt(unreadMessages));
    } */
  }, [location.pathname, localStorage]);

  return (
    <Sidebar
      collapsible="icon"
      className="overflow-hidden [&>[data-sidebar=sidebar]]:flex-row"
      {...props}
    >
      {/* This is the first sidebar */}
      {/* We disable collapsible and adjust width to icon. */}
      {/* This will make the sidebar appear as icons. */}
      <Sidebar
        collapsible="none"
        className="!w-[calc(var(--sidebar-width-icon)_+_1px)] border-r"
      >
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton
                size="lg"
                asChild
                className="md:h-8 md:p-0 hover:bg-current"
              >
                <Link to="/">
                  <div className="flex aspect-square size-8 items-center justify-center rounded-lg text-sidebar-primary-foreground">
                    <img
                      src="/apple-touch-icon.png"
                      style={{ height: 32, margin: "auto" }}
                      alt="logo"
                    />
                  </div>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">Graftus</span>
                    {/* <span className="truncate text-xs">Enterprise</span> */}
                  </div>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent className="px-1.5 md:px-0">
              <SidebarMenu>
                {navMain.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      tooltip={{
                        children: item.title,
                        hidden: false,
                      }}
                      isActive={
                        item.url === "/"
                          ? location.pathname === "/"
                          : location.pathname.includes(item.url)
                      }
                      className="px-2.5 md:px-2"
                      asChild
                    >
                      <Link to={item.url}>
                        <item.icon />
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                    {/*   {item.title === "Matchningar" && unreadMessages > 0 && (
                      <SidebarMenuBadge className="bg-red-500 absolute right-[-10px] top-[-12px] group[data-collapsible='icon'] group-data-[collapsible=icon]:flex visible text-white text-[8px]">
                        {unreadMessages}
                      </SidebarMenuBadge>
                    )} */}
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarContent>
            {businessProfile?.isAdmin && (
              <SidebarMenu>
                {adminNav.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton
                      tooltip={{
                        children: item.title,
                        hidden: false,
                      }}
                      asChild
                    >
                      <Link to={item.url}>
                        <item.icon />
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            )}
          </SidebarContent>
          {memberProfile && (
            <NavUser user={memberProfile} isAdmin={businessProfile?.isAdmin} />
          )}
        </SidebarFooter>
      </Sidebar>

      {/* This is the second sidebar */}
      {/* We disable collapsible and let it fill remaining space */}
      {location.pathname.includes("chat") && <ChatSidebar />}
    </Sidebar>
  );
}

function NavUser({ user, isAdmin }: { user: any; isAdmin: boolean }) {
  const { isMobile } = useSidebar();
  /*   const [downloadedImage, setDownloadedImage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false); */
  const [signOut] = useSignOut(auth);

  /*  useEffect(() => {
    setIsLoading(true);
    getDownloadURL(
      ref(storage, `businessUsersAvatars/${user.uid}/profile_image`)
    )
      .then((url) => {
        setDownloadedImage(url);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  }, [user]); */

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground md:h-8 md:p-0"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage src={user.avatar} alt={user.name} />
                <AvatarFallback className="rounded-lg">
                  {user.name
                    ?.split(" ")
                    .map((word: string) => word[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                <span className="truncate font-semibold">{user.name}</span>
                <span className="truncate text-xs">{user.email}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            side={isMobile ? "bottom" : "right"}
            align="end"
            sideOffset={4}
          >
            <DropdownMenuLabel className="p-0 font-normal">
              <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage src={user.avatar} alt={user.name} />
                  <AvatarFallback className="rounded-lg">
                    {user.name
                      ?.split(" ")
                      .map((word: string) => word[0])
                      .join("")}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{user.name}</span>
                  <span className="truncate text-xs">{user.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            {/*   <DropdownMenuGroup>
              <DropdownMenuItem>
                <Sparkles />
                Upgrade to Pro
              </DropdownMenuItem>
            </DropdownMenuGroup>
            <DropdownMenuSeparator /> */}
            <DropdownMenuGroup>
              <DropdownMenuItem asChild>
                <Link to="/profile">
                  <User />
                  Profil
                </Link>
              </DropdownMenuItem>
              {/*  <DropdownMenuItem>
                <CreditCard />
                Billing
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Bell />
                Notifications
              </DropdownMenuItem> */}
            </DropdownMenuGroup>
            {isAdmin && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  {adminNav.map((item) => (
                    <DropdownMenuItem key={item.title}>
                      <Link to={item.url}>{item.title}</Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuGroup>
              </>
            )}
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => signOut()}>
              <LogOut />
              Logga ut
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
