import { Button } from "./ui/button";
import { useProfile } from "@/context/ProfileContext";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { Candidate } from "@/types/candidate";
import { getDocumentUrl } from "./document-buttons";

interface TeamtailorCandidateButtonProps {
  candidate: Candidate;
}

export default function TeamtailorCandidateButton({
  candidate,
}: TeamtailorCandidateButtonProps) {
  const { businessProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  console.log("Cadnidto ", candidate);

  const sendToTeamtailor = async () => {
    if (!businessProfile?.integrations?.teamtailor?.apiKey) {
      toast({
        title: "Fel",
        description:
          "Ingen Teamtailor API-nyckel konfigurerad. Vänligen lägg till en i Integrationer-inställningar.",
        variant: "destructive",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch("https://api.teamtailor.com/v1/candidates", {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Authorization: `Token token=${businessProfile.integrations.teamtailor.apiKey}`,
          "X-Api-Version": "20210218",
        },
        body: JSON.stringify({
          data: {
            type: "candidates",
            attributes: {
              "first-name": candidate.realFirebaseUserData?.givenName,
              "last-name": candidate.realFirebaseUserData?.surname,
              email: candidate.realFirebaseUserData?.email,
              phone: candidate.realFirebaseUserData?.phoneNumber || "",
              resume: candidate.realFirebaseUserData?.hasCv
                ? await getDocumentUrl(candidate.uid, "cv")
                : "",
              tags: "graftus",
            },
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast({
        description: "Kandidaten har skickats till Teamtailor",
      });
    } catch (error) {
      console.error("Fel vid sändning till Teamtailor:", error);
      toast({
        title: "Fel",
        description:
          "Det gick inte att skicka kandidaten till Teamtailor. Försök igen.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={sendToTeamtailor}
      variant="ghost"
      className="hover:bg-blue-100 transition-colors"
      disabled={isLoading || !businessProfile?.integrations?.teamtailor?.apiKey}
      title={isLoading ? "Skickar..." : "Skicka till Teamtailor"}
    >
      <img
        src="https://www.google.com/s2/favicons?domain=teamtailor.com&sz=32"
        alt="Teamtailor"
        className="h-5 w-5"
      />
      {isLoading ? "Skickar..." : "Skicka till Teamtailor"}
    </Button>
  );
}
