import NewChatsList from "./Chat/new-chats-list";
import JobAdsDropdown from "./job-ads-dropdown";
import {
  Sidebar,
  SidebarHeader,
  SidebarInput,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  useSidebar,
} from "./ui/sidebar";
import { useAdRedirect } from "@/lib/useAds";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUsers } from "@/context/UsersContext";
import {
  collection,
  limit,
  orderBy,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "@/config/firebaseConfig";
import { Candidate } from "@/types/candidate";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Skeleton } from "./ui/skeleton";
import { Label } from "./ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export default function ChatSidebar() {
  const { selectedAd, adsSnapshot, navigateToAd } = useAdRedirect("/chat");
  const { id: id, userId: selectedCandidate } = useParams<{
    id?: string;
    userId?: string;
  }>();
  const { setOpen } = useSidebar();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [senderFilter, setSenderFilter] = useState("all");

  console.log("selectedCandidate", selectedCandidate);

  const { users } = useUsers();
  const matchedUsersRef = collection(db, `TopLevelReciprocalMatches`);
  const matchedUsersQuery = id
    ? query(
        matchedUsersRef,
        where("jobAdId", "==", id),
        /*  where("status", "not-in", [
          "businessDeclined", 
          "candidateDeclined",
          "archived",
          "hired",
        ]),
        orderBy("status"), */
        orderBy("timestamp", "desc")
      )
    : null;
  const [matchedUsers, matchedUsersLoading, matchedUsersError] =
    useCollectionData(matchedUsersQuery);
  const [sortedMatches, setSortedMatches] = useState<Candidate[]>([]);

  useEffect(() => {
    if (!matchedUsers || !id) {
      setSortedMatches([]);
      return;
    }

    setIsLoading(true);

    // First, create a base list of all matches
    const baseMatches = matchedUsers
      .map((match) => {
        const user = users.find((user) => user.uid === match.candidateId);
        return user ? { ...user, ...match, latestMessage: null } : null;
      })
      .filter(Boolean);

    // Sort by match timestamp initially
    const sortedByMatchTime = baseMatches.sort((a, b) => {
      const timestampA = a.timestamp?.toDate().getTime() || 0;
      const timestampB = b.timestamp?.toDate().getTime() || 0;
      return timestampB - timestampA;
    });

    setSortedMatches(sortedByMatchTime);

    // Then set up real-time listeners for messages
    const unsubscribers = matchedUsers.map((match) => {
      const messagesRef = collection(db, `Chats/${id}/Messages`);
      const latestMessageQuery = query(
        messagesRef,
        where("sellerUid", "==", match.candidateId),
        orderBy("timestamp", "desc"),
        limit(1)
      );

      return onSnapshot(latestMessageQuery, (snapshot) => {
        const latestMessage = snapshot.docs[0]?.data();
        //@ts-ignore
        setSortedMatches((prev) => {
          const updated = prev.map((user) => {
            if (user.candidateId === match.candidateId) {
              return { ...user, latestMessage };
            }
            return user;
          });

          // Sort by latest message timestamp if available, otherwise fall back to match timestamp
          return updated.sort((a, b) => {
            //@ts-ignore
            const timestampA = a.latestMessage?.timestamp?.toDate?.().getTime() || a.timestamp?.toDate().getTime() || 0;
            //@ts-ignore
            const timestampB = b.latestMessage?.timestamp?.toDate?.().getTime() || b.timestamp?.toDate().getTime() || 0;
            return timestampB - timestampA;
          });
        });
      });
    });

    setIsLoading(false);

    return () => {
      unsubscribers.forEach((unsub) => unsub());
    };
  }, [matchedUsers, id, users]);

  useEffect(() => {
    if (location.pathname.includes("chat")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname]);

  const filteredMatches = sortedMatches.filter((match) => {
    console.log("match", match);
    const name = `${match.givenName || ""} ${match.surname || ""}`.trim();
    const nameMatches = name.toLowerCase().includes(searchTerm.toLowerCase());

    if (senderFilter === "all") {
      return nameMatches;
    } else if (senderFilter === "ai") {
      return (
        //@ts-ignore
        nameMatches && match.latestMessage?.sender === "business-automated"
      );
    } else if (senderFilter === "business") {
      //@ts-ignore
      return nameMatches && match.latestMessage?.sender === "business";
    } else if (senderFilter === "seller") {
      //@ts-ignore
      return nameMatches && match.latestMessage?.sender === "seller";
    }
    return nameMatches;
  });

  const activeMatches = filteredMatches.filter(
    (match) =>
      match.status !== "archived" &&
      match.status !== "hired" &&
      match.status !== "businessDeclined" &&
      match.status !== "candidateDeclined"
  );
  const archivedMatches = filteredMatches.filter(
    (match) =>
      match.status === "archived" ||
      match.status === "hired" ||
      match.status === "businessDeclined" ||
      match.status === "candidateDeclined"
  );

  return (
    <Sidebar collapsible="none" className="hidden md:flex flex-1">
      {matchedUsersError ? (
        <p>Error: {JSON.stringify(matchedUsersError)}</p>
      ) : (
        <>
          <SidebarHeader className="gap-3.5 border-b p-4">
            <div className="flex w-full items-center justify-between">
              {adsSnapshot && (
                <div className="flex flex-col">
                  <Label htmlFor="job-ads-dropdown" className="mb-1">
                    Välj annons
                  </Label>
                  <JobAdsDropdown
                    /* id="job-ads-dropdown" */
                    jobAdsSnapshot={adsSnapshot}
                    onAdClick={navigateToAd}
                    selectedAd={selectedAd}
                  />
                </div>
              )}
              {/*  <Label className="flex items-center gap-2 text-sm">
                <span>Olästa</span>
                <Switch className="shadow-none" />
              </Label> */}
            </div>
            <div className="flex gap-2">
              <div>
                <Label>Sök kandidat</Label>
                <SidebarInput
                  className="h-[36px]"
                  placeholder="T.ex. Erik Svensson..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div>
                <Label>Senast svar från</Label>
                <Select value={senderFilter} onValueChange={setSenderFilter}>
                  <SelectTrigger className="w-[120px] bg-background">
                    <SelectValue placeholder="Filter" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">Alla</SelectItem>
                    <SelectItem value="ai">Julia AI</SelectItem>
                    <SelectItem value="business">Företag</SelectItem>
                    <SelectItem value="seller">Kandidat</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <SidebarGroup className="px-0">
              <SidebarGroupContent>
                {isLoading || matchedUsersLoading ? (
                  // Display skeletons while loading
                  Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-4 p-4"
                      >
                        <Skeleton className="h-12 w-12 rounded-full" />
                        <div className="space-y-2">
                          <Skeleton className="h-4 w-[200px]" />
                          <Skeleton className="h-4 w-[150px]" />
                        </div>
                      </div>
                    ))
                ) : (
                  <Tabs defaultValue="active">
                    <TabsList className="grid w-full grid-cols-2">
                      <TabsTrigger value="active">Aktiva</TabsTrigger>
                      <TabsTrigger value="archived">Arkiverade</TabsTrigger>
                    </TabsList>
                    <TabsContent value="active">
                      <NewChatsList
                        matches={activeMatches}
                        selectedAdId={selectedAd?.id!}
                      />
                    </TabsContent>
                    <TabsContent value="archived">
                      <NewChatsList
                        matches={archivedMatches}
                        selectedAdId={selectedAd?.id!}
                      />
                    </TabsContent>
                  </Tabs>
                )}
              </SidebarGroupContent>
            </SidebarGroup>
          </SidebarContent>
        </>
      )}
    </Sidebar>
  );
}
